@import 'https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i';

::-webkit-scrollbar {
    width: 8px;
    background-color: #222222;
}

::-webkit-scrollbar-thumb {
    background-color: #444444;
    border-radius: 9em;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #04b4e0;
}

.arrow-navitation {
    display: none;
}

.hamburger {
    display: none;
}

.container {
    height: 100vh;
}

.container .row {
    height: 100%;
}

.content {
    color: white;
    height: 100%;
}

html {
    margin: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
}

body {
    background-color: #04b4e0;
    margin: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    font-size: 15px;
    font-family: poppins, Helvetica, sans-serif;
    line-height: 1.65em;
    overflow: hidden;
    color: #d5d5d5;
}

h1, h2, h3, h4, h5, h6 {
    color: #eee;
    font-family: poppins, Helvetica, sans-serif;
    font-weight: 600
}

.header {
    display: inline-block;
    float: left;
    width: 100%;
    min-height: 100%;
    text-align: center;
    padding: 65px 30px 45px;
    background-color: #444444;
    overflow-y: scroll;

}

.header-photo {
    position: relative;
    width: 180px;
    margin: 0 auto 30px;
    z-index: 1
}

.header-photo img {
    max-width: 100%;
    background-color: #fff;
    border: 3px solid #fff;
    border-radius: 300px
}
.header-logo img{
    width: 150px;
    height: 150px;
}

.header-photo:after {
    position: absolute;
    opacity: .3;
    top: 7%;
    left: 7%;
    border-radius: 300px;
    content: '';
    height: calc(100% + 0px);
    width: calc(100% + 0px);
    background-image: -webkit-repeating-radial-gradient(center center, #fff, #fff 1px, transparent 0px, transparent 100%);
    background-image: -moz-repeating-radial-gradient(center center, #fff, #fff 1px, transparent 0px, transparent 100%);
    background-image: -ms-repeating-radial-gradient(center center, #fff, #fff 1px, transparent 0px, transparent 100%);
    -webkit-background-size: 6px 6px;
    -moz-background-size: 6px 6px;
    background-size: 6px 6px;
    z-index: 0
}

.header-titles h2 {
    font-size: 36px;
    font-weight: 600;
    color: #f5f5f5;
    margin: 5px 0 7px;
    line-height: 1.2em
}

.header-titles h4 {
    font-size: 18px;
    font-weight: 300;
    color: #bbb;
    margin: 5px 0;
    line-height: 1.2em
}

.social-links {
    margin: 20px 0
}

.social-links ul {
    list-style: none;
    padding: 0
}

.social-links ul li {
    display: inline-block
}

.social-links ul li a {
    color: #fff;
    border-radius: 20px;
    font-size: 18px;
    padding: 0;
    height: 30px;
    width: 30px;
    display: block;
    line-height: 30px;
    text-align: center;
    opacity: .9
}

.social-links ul li a:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, .2)
}

.header-buttons {
    margin-top: 50px
}

.header-buttons .btn-primary{
    background-color: transparent;
    color: #f5f5f5;
    border-color: #f5f5f5;
    border-radius: 20px;

}

.header-buttons .btn-primary:hover {
    background-color: #fff;
    color: #04b4e0;
    border-color: #fff;
    transition: all .3s ease-in-out;
}

.contact-form .btn-send, .portfolio .btn-primary{
    background-color: transparent;
    color: #f5f5f5;
    border-color: #05b4e0;
    border-radius: 20px;
    position: relative;
    top: -10px;
}

.contact-form .btn-send:hover , .portfolio .btn-primary:hover{
    background-color: #04b4e0;
    color: #fff;
    border-color: #fff
}

.navigation {
    position: fixed;
    color: white;
    min-height: 100%;
    max-width: 100%;
    background-color: #222222;
    padding: 10px 0 0 0;
    border: 1px solid #333;
}

.main-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.main-menu a {
    text-decoration: none;

}

.menu-icon {
    display: block;
    font-size: 30px;
    color: #eee;
    text-align: center;
}

.link-text {
    color: #eee;
    font-size: 11px;
    display: flex;
    justify-content: center;
}

.menu-item {
    margin: 15px;
    border-bottom: 1px solid #333;
}

.active {
    border-bottom: 5px solid #333;
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    border-right: 1px solid #333;
}

.home {
    color: white;
    height: 100%;
    width: 100%;
    background: #222222;
    padding: 10px 0 0 0;
    text-align: center;
}

.title {
    padding: 15% 0 0 0;
}

.title h2 {
    color: #f5f5f5;
    font-size: 40px;
    margin: 0;
    text-align: center;
}

.title .en h2 {
    line-height: 74px;
}

.carousel {
    margin: 0 auto;
    height: 23px;
    width: 180px;
}


.section {
    color: white;
    max-height: 100vh;
    max-width: 100%;
    background-color: #222222;
    padding: 20px 20px 20px 20px;
    overflow-y: auto;
}

.page-title {
    display: inline-block;
    position: relative;
    padding-right: 25px;
}

.page-title h2 {
    position: relative;
    font-size: 32px;
    z-index: 1
}

.page-title h2 span {
    color: #04b4e0
}

.page-title:after {
    position: absolute;
    opacity: .45;
    top: 17px;
    right: 0;
    content: '';
    height: 25px;
    width: 50px;
    background-image: -webkit-repeating-radial-gradient(center center, #04b4e0, #04b4e0 1px, transparent 0px, transparent 100%);
    background-image: -moz-repeating-radial-gradient(center center, #04b4e0, #04b4e0 1px, transparent 0px, transparent 100%);
    background-image: -webkit-repeating-radial-gradient(center center, #04b4e0, #04b4e0 1px, transparent 0px, transparent 100%);
    -webkit-background-size: 6px 6px;
    -moz-background-size: 6px 6px;
    background-size: 6px 6px;
    z-index: 0
}

.white-space-50 {
    padding-bottom: 50px
}

.white-space-40 {
    padding-bottom: 40px
}

.white-space-30 {
    padding-bottom: 30px
}

.white-space-20 {
    padding-bottom: 20px
}

.white-space-10 {
    padding-bottom: 10px
}

.info-list {
    margin-top: 20px;
}

.info-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.info-list .title {
    color: #04b4e0;
    margin-right: 5px;
    font-weight: 600;
}


.block-title {
    display: inline-block;
    position: relative;
    padding-right: 12px;
    padding-bottom: 10px;
    margin-bottom: 15px
}

.block-title h3 {
    position: relative;
    font-size: 21px;
    z-index: 1
}

.info-block-w-icon {
    margin-bottom: 25px
}

.info-block-w-icon .ci-text h4 {
    margin: 5px 0
}

.info-block-w-icon .ci-text p {
    font-size: .95em
}

.info-block-w-icon .ci-icon {
    display: table-cell;
    width: 54px;
    padding-right: 25px
}

.info-block-w-icon i {
    position: relative;
    font-size: 42px;
    color: #04b4e0;
    opacity: .7
}


.page-title {
    display: inline-block;
    position: relative;
    padding-right: 25px;
    padding-bottom: 15px;
    margin-bottom: 30px
}

.page-title h2 {
    position: relative;
    font-size: 32px;
    z-index: 1
}

.page-title h2 span {
    color: #04b4e0
}

.page-title:after {
    position: absolute;
    opacity: .45;
    top: 10px;
    right: 0;
    content: '';
    height: 30px;
    width: 50px;
    background-image: -webkit-repeating-radial-gradient(center center, #04b4e0, #04b4e0 1px, transparent 0px, transparent 100%);
    background-image: -moz-repeating-radial-gradient(center center, #04b4e0, #04b4e0 1px, transparent 0px, transparent 100%);
    background-image: -ms-repeating-radial-gradient(center center, #04b4e0, #04b4e0 1px, transparent 0px, transparent 100%);
    -webkit-background-size: 6px 6px;
    -moz-background-size: 6px 6px;
    background-size: 6px 6px;
    z-index: 0
}

.block-title {
    display: inline-block;
    position: relative;
    padding-right: 12px;
    padding-bottom: 10px;
    margin-bottom: 15px
}

.block-title h3 {
    position: relative;
    font-size: 21px;
    z-index: 1
}

.clearfix:before, .clearfix:after {
    content: " ";
    display: table
}

.clearfix:after {
    clear: both
}

.timeline-second-style .timeline-item {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding-bottom: 15px
}

.timeline-second-style .timeline-item:last-child {
    padding-bottom: 0
}

.timeline-second-style .left-part {
    width: 30%;
    display: table-cell;
    padding-right: 25px;
    min-height: 100%;
    text-align: right;
    vertical-align: top
}

.timeline-second-style .right-part {
    width: 70%;
    display: table-cell;
    padding-left: 25px;
    padding-right: 15px;
    vertical-align: top
}

.timeline-second-style .right-part p, .timeline-second-style .right-part p > * {
    font-size: .82em
}

.timeline-second-style .divider {
    position: absolute;
    top: 0;
    left: 30%;
    bottom: 0;
    width: 1px;
    background-color: #444
}

.timeline-second-style .divider:before {
    content: '';
    display: block;
    position: absolute;
    margin-top: 4px;
    width: 17px;
    height: 17px;
    position: absolute;
    margin-left: -8px;
    border-radius: 10px;
    background-color: #04b4e0;
    opacity: .25;
    z-index: 0
}

.timeline-second-style .divider:after {
    content: '';
    display: block;
    position: absolute;
    margin-top: 8px;
    width: 9px;
    height: 9px;
    margin-left: -4px;
    background-color: #222;
    border-radius: 5px;
    border: 2px solid #04b4e0;
    z-index: 1
}

.timeline-second-style .item-title {
    font-size: 16px;
    margin-bottom: 3px
}

.timeline-second-style .item-period {
    color: #aaa;
    margin: 3px 0;
    font-size: 14px;
    line-height: 1.4em
}

.timeline-second-style .item-company {
    display: block;
    margin: 0 0 4px;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.45em;
    color: #a5a6a7
}

.timeline-second-style .timeline-item:last-child .right-part {
    padding-bottom: 0
}

.timeline-second-style .item-logo {
    display: block;
    margin-bottom: 10px
}

.timeline-second-style .item-logo img {
    max-height: 40px
}

.white-space-50 {
    padding-bottom: 50px
}

.block-title {
    display: inline-block;
    position: relative;
    padding-right: 12px;
    padding-bottom: 10px;
    margin-bottom: 15px
}

.block-title h3 {
    position: relative;
    font-size: 21px;
    z-index: 1
}

.block-title h3 span {
    color: #04b4e0
}

.block-title:after {
    position: absolute;
    opacity: .45;
    top: 10px;
    right: 0;
    content: '';
    height: 20px;
    width: 30px;
    background-image: -moz-repeating-radial-gradient(center center, #04b4e0, #04b4e0 1px, transparent 0px, transparent 100%);
    background-image: -ms-repeating-radial-gradient(center center, #04b4e0, #04b4e0 1px, transparent 0px, transparent 100%);
    background-image: -webkit-repeating-radial-gradient(center center, #04b4e0, #04b4e0 1px, transparent 0px, transparent 100%);
    -webkit-background-size: 6px 6px;
    -moz-background-size: 6px 6px;
    background-size: 6px 6px;
    z-index: 0
}


.skills-info.skills-second-style {
    margin-bottom: 30px
}

.skills-info.skills-second-style h4 {
    font-size: 13px;
    line-height: 1.1em;
    position: relative;
    float: left;
    margin: 0 0 4px
}

.skills-second-style .skill-container {
    position: relative;
    display: inline-block;
    background-color: #222;
    border: 1px solid #04b4e0;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 10px;
    margin-bottom: 8px;
    width: 100%
}

.skills-second-style .skill-container:before {
    content: '';
    position: absolute;
    background-color: #04b4e0
}

.skills-second-style .skill-value {
    font-size: 11px;
    line-height: 1.1em;
    position: relative;
    float: right;
    margin: 0 0 4px;
    color: #aaa
}

.skills-second-style .skill-percentage {
    background-color: #04b4e0;
    border: 2px solid #222;
    -moz-border-radius: 9px;
    -webkit-border-radius: 9px;
    border-radius: 9px;
    height: 8px;
    padding: 0
}

.skills-second-style .skill-container.skill-1 .skill-percentage {
    width: 30%
}

.skills-second-style .skill-container.skill-2 .skill-percentage {
    width: 40%
}

.skills-second-style .skill-container.skill-3 .skill-percentage {
    width: 20%
}

.skills-second-style .skill-container.skill-4 .skill-percentage {
    width: 50%
}

.skills-second-style .skill-container.skill-5 .skill-percentage {
    width: 40%
}


.pt-page .skills-second-style .skill-percentage, .pt-page .skills-second-style .skill-value {
    -webkit-transition: all 2s ease-in-out;
    -moz-transition: all 2s ease-in-out;
    -o-transition: all 2s ease-in-out;
    -ms-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out
}

.js .subpages:not(.one-page-layout) .pt-page:not(.pt-page-current) .skills-second-style .skill-percentage {
    width: 0 !important
}

.js .subpages:not(.one-page-layout) .pt-page:not(.pt-page-current) .skills-second-style .skill-value {
    left: 26px !important
}

.white-space-10 {
    padding-bottom: 10px
}


.knowledges {
    list-style: none;
    padding: 0;
}

.knowledges li {
    display: inline-block;
    background-color: #04b4e0;
    border-radius: 3px;
    color: #fff;
    padding: 1px 10px;
    margin: 3px 2px;
    font-size: 13px
}


.info-block-w-icon {
    margin-bottom: 25px
}

.info-block-w-icon .ci-text h4 {
    margin: 5px 0
}

.info-block-w-icon .ci-text p {
    font-size: .95em
}

.info-block-w-icon .ci-icon {
    display: table-cell;
    width: 54px;
    padding-right: 25px
}

.info-block-w-icon i {
    position: relative;
    font-size: 42px;
    color: #04b4e0;
    opacity: .7
}

.lm-info-block {
    position: relative;
    text-align: center;
    width: 100%;
    display: block;
    margin: 0 0 15px;
    background-color: #333;
    padding: 20px 10px;
    border: 1px solid #444;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    overflow: hidden;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.lm-info-block i {
    position: relative;
    font-size: 33px;
    color: #04b4e0;
    z-index: 1
}

.lm-info-block h4 {
    font-size: 15px;
    margin-top: 8px
}

.lm-info-block .lm-info-block-value {
    font-size: 30px;
    line-height: 30px;
    font-weight: 400;
    display: block;
    margin: 10px 0;
    color: #04b4e0
}

.lm-info-block .lm-info-block-value:empty {
    margin: 0
}

.contact-link {
    color: #fff;
    font-size: 18px;
    opacity: .9;
}


ul.header-main-menu {
    list-style-type: none;
    padding: 0;
}

.header-navigation li.menu-item {
    border: none;
}

.header-navigation li.menu-item a.nav-anim {
    padding: 12px 0;
    text-decoration: none;
    font-family: poppins, Helvetica, sans-serif;
}

.header-navigation span.link-text {
    font-size: 16px;
}

.lmpixels-map {
    width: 100%;
}

.horizontalSlider___281Ls {
    height: 100%;
}

.controls.two-columns .left-column {
    width: 47%;
    float: left;
    margin-right: 3%
}

.controls.two-columns .right-column {
    width: 50%;
    float: right
}

.form-control, .form-control:focus {
    height: 42px
}

.form-control, .form-control:focus, .has-error .form-control, .has-error .form-control:focus, input[type=search], input[type=password], input[type=text] {
    position: relative;
    border: 2px solid #999;
    border-radius: 5px;
    display: block;
    font-size: 1em;
    line-height: 1.4;
    padding: 5px 25px 5px 12px;
    width: 100%;
    background: 0 0;
    background-color: transparent;
    text-align: left;
    color: inherit;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: none;
    font-family: poppins, Helvetica, sans-serif;
    box-shadow: 0 10px 10px -8px rgba(0, 0, 0, .1)
}

.help-block, .captcha {
    color: red;
    font-size: 10px;
    height: 15px;
    line-height: 16px;
}

.formlabel {
    height: 24px;
    margin: 0;
}

.form-control.form-control-text {
    height: 259px;
}

.send-message-title {
    margin: 0;
}

.recaptcha {
    transform: scale(0.8);
    -webkit-transform: scale(0.83);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    display: inline-block;
}

.captcha, .send-successful {
    visibility: hidden;
    color: red;
    position: relative;
    top: -10px;
}

.captcha.danger-captcha {
    visibility: visible;
}

.send-successful.fine {
    visibility: visible;
    color: #04b4e0;
    top: -10;
}

.futer-form {
    display: flex;
    flex-direction: column;
}

.portfolio .section {
    display: flex;
}

.portfolio .card{
    width: 15rem;
    display: inline-block;
    margin: 5px;
    background-color: #444444;
    align-items: center;
}
.portfolio .card .divider{
    display: inline-block;
    background-color: #222222;
    height: 2px;
    width: 80%;
    margin-left: 10%;
}
.portfolio .card img{
width: 100%;
height: 100%;
}
.portfolio .card .card-body{
    width: 100%;
    background-color: #444444
}

.portfolio .btn-primary{
    width: 100%;
}

@media (max-width: 1199.98px) {
    .header {
        padding: 65px 0 45px 0;
    }
}

@media (min-width: 991.98px) {
    .header-navigation {
        display: none;
    }
}

@media (max-width: 991px) {
    .home .title {
        padding: 20% 0 0 0;
    }

    .header {
        position: fixed;
        left: -250px;
        width: 300px;
        z-index: 1;
        transition: 0.5s all
    }

    .header.active {
        left: 0;
        border: none;
        height: 100%;
        overflow-y: auto;
    }

    .header-navigation {
        display: block;
    }

    .navigation {
        position: fixed;
        left: -100%;
    }

    .content {
        position: fixed;
        width: 95%;
        left: 5%;
    }

    .hamburger {
        display: block;
        position: absolute;
        left: 2px;
        top: 20px;
        transform: translateY(-50%);
        width: 37px;
        height: 12px;
        z-index: 2;
    }

    .arrow-navitation {
        display: block;
        position: absolute;
        width: 26px;
        height: 60px;
        left: 8px;
        top: 40px;
        border-radius: 45px;
        z-index: 2;
        background-color: rgba(0, 0, 0, .6);
        padding: 5px 5px;
        border: 2px solid #04b4e0
    }

    .arrow-navitation div {
        color: white;
        margin-top: 11px;
    }

    .arrow-forward {
        padding-left: 5px;
    }

    .arrow-backward {
        top: 110px;
        padding-left: 3px;
    }

    .hamburger span {
        display: block;
        height: 4px;
        width: 100%;
        background-color: #fff;
        margin-bottom: 4px;
        transition: 0.5s all;
    }

    .hamburger_active span {
        margin-bottom: -2px;
    }

    .hamburger_active span:nth-child(1) {
        transform: translateY(4px) rotate(-45deg);
    }

    .hamburger_active span:nth-child(2) {
        display: none;
    }

    .hamburger_active span:nth-child(3) {
        transform: translateY(2px) rotate(45deg);
    }
}

@media (max-width: 768px) {
    .info-list ul li .value {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .content {
        left: 9%;
    }

    .info-list ul li .value {
        font-size: 19px;
    }

    .info-list ul li .title {
        font-size: 19px;
    }
}

@media (max-width: 420px) {
    .title h2 {
        font-size: 35px;
    }
}